import { Container } from '@mui/material'
import CKLocationPicker from 'components/Form/CKLocationPicker'
import CKSocialsForm from 'components/Form/CKSocialsForm'
import MKAlert from 'components/MaterialKit/MKAlert'
import MKButton from 'components/MaterialKit/MKButton'
import {
    CustomLineupIcon,
    ImageIcon,
    InfoIcon,
    LocationIcon,
    MicIcon,
    SaveIcon,
    ShareIcon,
    TicketIcon,
} from 'components/UI/CKIcons'
import { CKPageTitle } from 'components/UI/CKPageContent'
import { CKSidebarTabs } from 'components/UI/CKSidebarTabs'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import { useEventEdit } from 'services/Event/UseEventEdit'
import { useUserLocations } from 'services/Location/LocationService'
import { EventPageContainer } from '../EventPageContainer'
import { CustomLineup } from './CustomLineup'
import EventImageForm from './EventImageForm'
import GeneralForm from './GeneralForm'
import SpotConfig from './SpotConfig'
import { TicketConfig } from './TicketConfig'

interface Props {}

const PageEventEdit: FC<Props> = () => {
    const { eventId } = useParams<{ eventId: string }>()

    const { hasLocations, isLoading: isLoadingLocations } = useUserLocations()

    const { event, loading, saving, save, errors } = useEventEdit(eventId)

    const sidebarTabs = {
        root: {
            General: {
                title: 'Algemeen',
                icon: <InfoIcon />,
                component: <GeneralForm event={event} errors={errors} />,
            },
            Location: {
                title: 'Locatie',
                icon: <LocationIcon />,
                component: (
                    <CKLocationPicker
                        defaultValue={event?.$location}
                        error={errors['location']}
                        onSelect={(e) => {
                            event.$location = e
                        }}
                        required
                    />
                ),
            },
            Images: {
                title: 'Afbeeldingen',
                icon: <ImageIcon />,
                component: event && <EventImageForm event={event} />,
            },
            Socials: {
                title: 'Socials',
                icon: <ShareIcon />,
                component: <CKSocialsForm model={event} />,
            },
            SpotConfig: {
                title: 'Spot Configuratie',
                icon: <MicIcon />,
                component: (
                    <SpotConfig
                        event={event}
                        onUpdate={(spots) => {
                            event.$spots = spots
                        }}
                    />
                ),
            },
            CustomLineup: {
                title: 'Custom line-up',
                icon: <CustomLineupIcon />,
                component: (
                    <CustomLineup
                        lineup={event?.$custom_lineup}
                        onLineupChange={(spots) => {
                            event.$custom_lineup = spots
                        }}
                        errors={errors?.custom_lineup}
                    />
                ),
            },
            Tickets: {
                title: 'Tickets',
                icon: <TicketIcon />,
                component: (
                    <TicketConfig
                        event={event}
                        onUpdate={(tickets) => {
                            event.$tickets = tickets
                        }}
                    />
                ),
            },
        },
    }

    return (
        <EventPageContainer event={event} loading={loading}>
            <CKPageTitle>
                {eventId === 'new' ? 'Creëer een evenement' : 'Pas evenement aan'}
            </CKPageTitle>

            {/* {event && !isLoadingLocations && <CKWizard steps={wizardSteps} />} */}
            <Container className="my-3">
                {!isLoadingLocations && !hasLocations && (
                    <MKAlert dismissible color="error">
                        Je hebt nog geen locaties om evenementen te organiseren.{' '}
                        <a
                            className="mx-1"
                            style={{
                                color: 'white',
                                textDecoration: 'underline',
                            }}
                            href="/admin/location/new"
                        >
                            <u>Klik hier</u>
                        </a>{' '}
                        om een locatie aan te maken!
                    </MKAlert>
                )}
                {event && !isLoadingLocations && (
                    <CKSidebarTabs
                        sections={sidebarTabs}
                        footer={
                            <div className="text-center">
                                <MKButton
                                    type="submit"
                                    variant="gradient"
                                    color="info"
                                    onClick={save}
                                    disabled={saving}
                                >
                                    {saving ? (
                                        <span className="mb-0 me-2">
                                            <ClipLoader size={15} color={'white'} />
                                        </span>
                                    ) : (
                                        <SaveIcon className="me-2" />
                                    )}
                                    {eventId === 'new' ? 'Creëer evenement!' : 'Sla op!'}
                                </MKButton>
                            </div>
                        }
                        hideGroup
                    />
                )}
            </Container>
        </EventPageContainer>
    )
}
export default PageEventEdit
