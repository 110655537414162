import DomainAddIcon from '@mui/icons-material/DomainAdd'
import { Container, Grid } from '@mui/material'
import bgImage from 'assets/images/bar_sidebar.jpg'
import { CKForm, CKFormBody } from 'components/Form/CKForm'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import CKSocialsForm from 'components/Form/CKSocialsForm'
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import MKInput from 'components/MaterialKit/MKInput'
import { CKHero } from 'components/UI/CKHero'
import { ImageIcon, InfoIcon, MoneyIcon, SaveIcon, ShareIcon } from 'components/UI/CKIcons'
import CKLoadingModel from 'components/UI/CKLoadingModel'
import { CKPageBadge, CKPageContent, CKPageTitle } from 'components/UI/CKPageContent'
import { CKSidebarTabs } from 'components/UI/CKSidebarTabs'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import { useOrganisationEdit } from 'services/Organisation/UseOrganisationEdit'
import { MollieConnect } from './MollieConnect'
import OrganisationImageForm from './OrganisationImageForm'

interface Props {}

const PageOrganisationEdit: FC<Props> = () => {
    const { organisationId } = useParams<{ organisationId: string }>()
    const { organisation, loading, saving, save, errors } = useOrganisationEdit(organisationId)

    const renderForm = () => {
        if (!organisation) return null
        return (
            <CKForm>
                <CKFormBody>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <MKInput
                                label="Naam"
                                title="Naam"
                                error={errors['name'] ? true : false}
                                defaultValue={organisation.$name}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    organisation.$name = e.target.value
                                }}
                                required
                                fullWidth
                            />
                            <CKFormFeedback>{errors['name']}</CKFormFeedback>
                        </Grid>
                        <Grid item xs={12}>
                            <MKInput
                                type="standard"
                                multiline
                                defaultValue={organisation.$description}
                                placeholder="Geef een beschrijving van de organisatie zodat mensen je weten wat je doet..."
                                title="Beschrijving"
                                label="Beschrijving"
                                onChange={(e) => {
                                    organisation.$description = e.target.value
                                }}
                                InputLabelProps={{ shrink: true }}
                                rows={8}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </CKFormBody>
            </CKForm>
        )
    }

    const sidebarSteps = {
        root: {
            algemeen: {
                title: 'Algemeen',
                icon: <InfoIcon />,
                component: renderForm(),
            },
            afbeeldingen: {
                title: 'Afbeeldingen',
                icon: <ImageIcon />,
                component: organisation && <OrganisationImageForm organisation={organisation} />,
            },
            socials: {
                title: 'Socials',
                icon: <ShareIcon />,
                component: <CKSocialsForm model={organisation} />,
            },
            payments: {
                title: 'Betalingen',
                icon: <MoneyIcon />,
                component: <MollieConnect organisation={organisation} />,
            },
        },
    }

    return (
        <>
            <MKBox>
                {organisation ? (
                    <>
                        <CKHero size="md" bgImage={organisation?.$bannerUrl ?? bgImage} />
                        <CKPageContent>
                            <CKPageBadge background="linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))">
                                <DomainAddIcon fontSize="large" style={{ color: 'white' }} />
                            </CKPageBadge>
                            <CKPageTitle>
                                {organisationId === 'new'
                                    ? 'Creëer een organisatie'
                                    : 'Pas organisatie aan'}
                            </CKPageTitle>
                            <Container className="my-3">
                                <CKSidebarTabs
                                    sections={sidebarSteps}
                                    footer={
                                        <div className="text-center">
                                            <MKButton
                                                type="submit"
                                                variant="gradient"
                                                color="info"
                                                onClick={save}
                                                disabled={saving}
                                            >
                                                {saving ? (
                                                    <span className="mb-0 me-2">
                                                        <ClipLoader size={15} color={'white'} />
                                                    </span>
                                                ) : (
                                                    <SaveIcon className="me-2" />
                                                )}
                                                {organisationId === 'new'
                                                    ? 'Creëer organisatie!'
                                                    : 'Sla op!'}
                                            </MKButton>
                                        </div>
                                    }
                                    hideGroup
                                />
                            </Container>
                        </CKPageContent>
                    </>
                ) : (
                    <CKLoadingModel loading={loading} notFoundMessage="Organisatie niet gevonden" />
                )}
            </MKBox>
        </>
    )
}
export default PageOrganisationEdit
