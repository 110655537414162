import ChevronRight from '@mui/icons-material/ChevronRight'
import { Card, Grid, useMediaQuery } from '@mui/material'
import MKBox from 'components/MaterialKit/MKBox'
import MKTypography from 'components/MaterialKit/MKTypography'
import { useUserContext } from 'contexts/UserContext'
import Event from 'models/Event'
import { FC } from 'react'
import { Link, useHistory } from 'react-router-dom'
import TimeUtils from 'utils/TimeUtils'
import { AccessTimeIcon, LocationIcon, MicIcon, TicketIcon } from '../CKIcons'
import { Lineup } from './LineUp'

import React from 'react'
import './style.scss'
export interface ICKEventRowProps {
    event: Event
    useAdminUrl?: boolean
    compact?: boolean
}

export const CKEventRow: FC<ICKEventRowProps> = ({ event, useAdminUrl, compact }) => {
    const history = useHistory()
    const isMobile = useMediaQuery('(max-width:768px)')
    const { user } = useUserContext()

    const lineupComponent = () => {
        return (
            <div className={`mt-2 mb-3 d-flex ${isMobile && 'justify-content-center'}`}>
                {event.showLineup() ? (
                    <Lineup lineup={event.$completeCombinedLineup} />
                ) : (
                    <>
                        {(!user || event.amountOpenSpots() > 0) && (
                            <MKTypography
                                variant="h6"
                                className="d-flex align-items-center event-card-available-spots mt-2"
                            >
                                <MicIcon className="me-1" />{' '}
                                {user
                                    ? event.amountOpenSpots() + ' spots beschikbaar!'
                                    : 'Line-up nog niet bekend'}
                            </MKTypography>
                        )}
                    </>
                )}
            </div>
        )
    }

    return (
        <Link to={useAdminUrl ? event.$adminUrl : event.$detailUrl} className="w-100">
            <Card
                sx={{ mt: isMobile ? 10 : 3 }}
                className="event-row text-start w-100"
                onClick={(e) => {
                    if (useAdminUrl) {
                        history.push(event.$adminUrl)
                    } else {
                        history.push(event.$detailUrl)
                    }
                }}
            >
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        lg={2}
                        sx={{ mt: isMobile && -10 }}
                        className={!isMobile && 'd-flex align-items-center'}
                    >
                        <MKBox width="100%" p={4}>
                            <MKBox
                                component="img"
                                src={event.$logoUrl}
                                alt={event.$name}
                                width="100%"
                                style={{
                                    maxHeight: isMobile ? '150px' : '200px',
                                    objectFit: 'contain',
                                    mixBlendMode: 'multiply',
                                }}
                                borderRadius="lg"
                                // shadow="lg"
                            />
                            {isMobile && lineupComponent()}
                        </MKBox>
                    </Grid>

                    <Grid item xs={12} md={6} lg={9} sx={{ mt: isMobile && -5 }}>
                        <MKBox
                            pt={{ xs: 1, lg: 2.5 }}
                            pb={2.5}
                            pr={4}
                            pl={{ xs: 4, lg: 1 }}
                            lineHeight={1}
                            className={isMobile && 'text-center'}
                        >
                            <MKTypography variant="h5">{event.$name}</MKTypography>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <MKTypography
                                        variant="h6"
                                        color={'info'}
                                        verticalAlign="middle"
                                        className={'event-row-time'}
                                    >
                                        <AccessTimeIcon className="me-1" />{' '}
                                        {TimeUtils.toDateString(event.$start_time, true)},{' '}
                                        {TimeUtils.toTimeString(event.$start_time)}
                                    </MKTypography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <MKTypography
                                        variant="h6"
                                        color={'info'}
                                        verticalAlign="middle"
                                        className={'event-row-time'}
                                    >
                                        <LocationIcon className="me-2" />
                                        {event.$location.$name}
                                        {event.$location.$address.$city &&
                                            ', ' + event.$location.$address.$city}
                                    </MKTypography>
                                </Grid>
                            </Grid>

                            {!isMobile && lineupComponent()}
                            <MKTypography
                                variant="body2"
                                color="text"
                                className="event-row-description"
                            >
                                {event.$description}
                            </MKTypography>
                            {event.$tickets.length > 0 && (
                                <div className={`${isMobile ? 'd-inline-block' : 'd-flex'} mt-3`}>
                                    <MKTypography
                                        variant="h6"
                                        color={'primary'}
                                        verticalAlign="middle"
                                        className={'event-row-time me-2'}
                                    >
                                        <TicketIcon className="me-1" /> Tickets:{' '}
                                    </MKTypography>
                                    <MKTypography
                                        variant="h6"
                                        className="m-0 me-1"
                                        color="primary"
                                        fontWeight="regular"
                                    >
                                        {event.$tickets.map((ticket, index) => (
                                            <React.Fragment key={`ticket-${ticket.$id}`}>
                                                {ticket.$title + ` (€${ticket.$price})`}
                                                {index < event.$tickets.length - 1 && ', '}
                                            </React.Fragment>
                                        ))}
                                    </MKTypography>
                                </div>
                            )}
                        </MKBox>
                    </Grid>
                    {!isMobile && (
                        <Grid
                            item
                            xs={1}
                            className="d-flex justify-content-center align-items-center"
                        >
                            <ChevronRight />
                        </Grid>
                    )}
                </Grid>
            </Card>
        </Link>
    )
}
