import { Container, Divider, Grid, useMediaQuery } from '@mui/material'
import bgImage from 'assets/images/bgImage.jpg'
import logoLight from 'assets/images/logos/logo_light.png'
import MKBox from 'components/MaterialKit/MKBox'
import CKEventOverview from 'components/UI/CKEventOverview'
import { CKHero } from 'components/UI/CKHero'
import { CKPageContent } from 'components/UI/CKPageContent'
import DefaultCounterCard from 'examples/Cards/CounterCards/DefaultCounterCard'
import { FC } from 'react'
import { useStatistics } from 'services/MetaTagService'
import { Banner } from './Banner'
import { QuickActions } from './QuickActions'

interface HomeProps { }

const Home: FC<HomeProps> = () => {
    const isMobile = useMediaQuery('(max-width:768px)')
    const { statistics, isLoading } = useStatistics()
    const defaultStats = {
        users: 0,
        locations: 0,
        events: 0
    }

    const stats = statistics || defaultStats

    const Counters = () => {
        const items = [
            {
                count: stats.users,
                suffix: '+',
                title: 'Open-micers',
                description:
                    'Comedians die stilletjes opweg zijn naar de top, kom maar eens kijken!',
            },
            {
                count: stats.events,
                title: 'Evenementen',
                description: 'Check hier of er in jouw buurt een optreden is!',
            },
            {
                count: stats.locations,
                title: 'Locaties',
                description: 'Wie weet spelen we binnenkort wel eens bij jou in de buurt!',
            },
        ]
        return (
            <MKBox component="section" py={3}>
                <Container>
                    <Grid 
                        container 
                        item 
                        xs={12} 
                        lg={9} 
                        sx={{ 
                            mx: 'auto',
                            justifyContent: { xs: 'center', md: 'flex-start' }
                        }}
                    >
                        {items.map((item, index) => {
                            return (
                                <Grid
                                    key={'counter-' + index}
                                    item
                                    xs={12}
                                    md={Math.round(12 / items.length)}
                                    display="flex"
                                    sx={{
                                        justifyContent: { xs: 'center', md: 'flex-start' }
                                    }}
                                >
                                    <DefaultCounterCard {...item} />
                                    {index < items.length - 1 && (
                                        <Divider
                                            orientation="vertical"
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    md: 'block',
                                                },
                                                mx: 0,
                                            }}
                                        />
                                    )}
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </MKBox>
        )
    }

    const Events = () => {
        return (
            <MKBox
                component="section"
                position="relative"
                py={6}
                px={{ xs: 0, lg: 0 }}
                ml={0}
                mt={6}
                style={{ flex: '1 1 auto' }}
                className="d-flex"
            >
                <Container className="d-flex flex-column p-0" style={{ flex: '1 1 auto' }}>
                    <CKEventOverview />
                </Container>
            </MKBox>
        )
    }

    

    return (
        <>
            <CKHero
                bgImage={bgImage}
                title={
                    <img
                        src={logoLight}
                        style={{ maxWidth: '300px', marginBottom: isMobile ? '0' : '5rem' }}
                    />
                }
                size="md"
                maxHeight={'750px'}
                height={'50vh'}
                description={
                    !isMobile &&
                    'Ontdek je lach en deel je humor op het meest bruisende comedy platform van Vlaanderen!'
                }
            />
            <CKPageContent>
                <Counters />
                <Events />
                <Banner />
                <QuickActions />
            </CKPageContent>
        </>
    )
}
export default Home
